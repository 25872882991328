<div class="align-with-nav">
    <div *ngIf="(authService.user | async)">
        <h2>Notebox Images</h2>
    
        <div class="add-new-container">
            <div class="existing-item-container" *ngIf="this.filesAndFoldersView.selectedItem">
                <h3>Current Item</h3>
                <div>
                    <label>Name: </label>
                    <span>{{ this.filesAndFoldersView.selectedItem.name }}</span>
                </div>
                <div>
                    <label>Is Active: </label>
                    <input type="checkbox" [(ngModel)]="this.filesAndFoldersView.selectedItem.isActive" (ngModelChange)="setCurrentItemActive($event)" />
                </div>
                <button class="delete-button" (click)="deleteCurrentItem()">Delete</button>
            </div>

            <h3>Add New</h3>
            <select (change)="onAddModeChanged($event.target.value)">
                <option [value]="AddMode.Image">Image</option>
                <option [value]="AddMode.Folder">Folder</option>
            </select>
            <div [hidden]="addMode !== AddMode.Image">
                <p>Upload a file</p>
                <input name="new-icon-upload" id="new-icon-upload" class="file-input" type="file" (change)="upload($event)" accept=".png,.jpg,.gif" />
                <label for="new-icon-upload" class="file-input-label">{{ newFileName !== null && newFileName !== "" ? newFileName : "Choose a file" }}</label>       
                
                <label>Make white transparent: <input type="checkbox" [(ngModel)]="makeWhiteTransparent" /></label>
                
                <div [hidden]="!hasImage">
                    <h4>Preview</h4>
                    <app-image-editor #imageEditor></app-image-editor>
                </div>

                <button [hidden]="!hasImage" class="upload-button" (click)="submitNewFile()">Upload</button>
            </div>
            <div [hidden]="addMode !== AddMode.Folder">
                <h4>Folder Name</h4>
                <input type="text" [(ngModel)]='newFolderName' />
                <button [disabled]="newFolderName === ''" (click)="addFolder()">Add Folder</button>
            </div>
        </div>
        
        <h3>Files &amp; Folders</h3>
        <div id="files-and-folders-container">
            <app-folder-view [filesAndFolders]="filesAndFolders" #filesAndFoldersView></app-folder-view>
            <ng-container *ngTemplateOutlet="filesAndFoldersView.componentTemplate"></ng-container>
        </div>
    </div>
</div>