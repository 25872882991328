import { ArticleModel, ArticleNode, ArticleNodeType } from './ArticleModel';
import { notEqual } from 'assert';

export class ArticleBuilder
{
    article: ArticleModel;
    constructor()
    {
        this.article = new ArticleModel();
    }

    clear()
    {
        this.article.nodes.length = 0;
    }

    addHeading(content: string)
    {
        let node = new ArticleNode();
        node.content = content;
        node.type = ArticleNodeType.Heading;
        this.article.nodes.push(node);
    }

    addSubheading(content: string)
    {
        let node = new ArticleNode();
        node.content = content;
        node.type = ArticleNodeType.Subheading;
        this.article.nodes.push(node);
    }

    addSubsubheading(content: string)
    {
        let node = new ArticleNode();
        node.content = content;
        node.type = ArticleNodeType.Subsubheading;
        this.article.nodes.push(node);
    }

    addParagraph(content: string)
    {
        let node = new ArticleNode();
        node.content = content;
        node.type = ArticleNodeType.Paragraph
        this.article.nodes.push(node);
    }

    addCode(content: string)
    {
        let node = new ArticleNode();
        node.content = content;
        node.type = ArticleNodeType.Code
        this.article.nodes.push(node);
    }

    addList(itemList: Array<string>)
    {
        let node = new ArticleNode();
        node.content = '';
        node.type = ArticleNodeType.List;
        node.children = itemList;
        this.article.nodes.push(node);
    }

    addEmbeddedProgram(programName: string)
    {
        let node = new ArticleNode();
        node.type = ArticleNodeType.EmbeddedProgram;
        node.content = programName;
        this.article.nodes.push(node);
    }

    addSeparator()
    {
        let node = new ArticleNode();
        node.type = ArticleNodeType.Separator;
        this.article.nodes.push(node);
    }

    addLink(href: string, text: string){
        let node = new ArticleNode();
        node.href = href;
        if (text === undefined)
        {
            node.content = node.href;
        }
        else
        {
            node.content = text;
        }
        node.type = ArticleNodeType.Link;
        this.article.nodes.push(node);
    }

    addImage(source: string)
    {
        let node = new ArticleNode();
        node.content =  source;
        node.type = ArticleNodeType.Image;
        this.article.nodes.push(node);
    }
}