<div class="align-with-nav">
    <h1 class="title-heading">About Me</h1>
    <div class="image-thumbnail-circular">
        <div class="image-thumbnail-inner"></div>
    </div>
    <p>
        I started developing software when I was around 12 years old. I got started because of my interest in developing games for the Xbox360 console using the XNA framework. Later, I developed and published my first iPhone application at 14. After that, I got involved heavily in C++ writing all sorts of programs like web browsers, encrpytion schemes, games, and more.
        <br /><br />
        Beyond programming and mathematics, I have been involved in the community as an Eagle Scout. My project was to design, build, and deliver thirty boxes to lock and store electronic devices for a school near my home.
        <br /><br />
        Other hobbies include outdoor activities, but mainly hiking in the Greater North County Area. Additionally, I enjoy the challenge of weight training and running at my local gym.
    </p>
    <h1 class="title-heading">Want to get in touch?</h1>
    <p>
        I'm pleased to hear it. You can find me on <a target="_blank" href="https://www.linkedin.com/in/austinsimpson1/">LinkedIn</a> or e-mail me directly at <a href="mailto:austin.simpson@me.com">austin.simpson@me.com</a>
    </p> 
</div>