<div class="align-with-nav">
  <h2 class="underlined-heading">Application</h2>
</div>
<figure style="overflow: visible;" id="qtspinner">
  <div class="align-with-nav">
    <img src="/assets/qtlogo.svg" />
    <strong>Qt for WebAssembly</strong>
    <div id="qtstatus"></div>
    <noscript>JavaScript is disabled. Please enable JavaScript to use this application.</noscript>
  </div>
</figure>
<canvas id="qtcanvas" oncontextmenu="event.preventDefault()" contenteditable="true"></canvas>
<a *ngIf='repoLink !== undefined && repoLink !== ""' [attr.href]="repoLink" target="_blank">Check out on GitHub!</a>
<p class="align-with-nav">Qt for WebAssembly is still in development. Expect some bugs in running this application.</p>
