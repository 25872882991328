import { Component, OnInit } from '@angular/core';
import { ProgrammingTableOfContentsService } from '../programming-table-of-contents.service';
import { ArticleBuilder } from '../models/ArticleBuilder';
import { ActivatedRoute } from '@angular/router';
import { ArticleModel } from '../models/ArticleModel'

@Component({
  selector: 'app-program-view',
  templateUrl: './program-view.component.html',
  styleUrls: ['./program-view.component.scss']
})
export class ProgramViewComponent implements OnInit {
  article: ArticleModel;
  title: string;
  constructor(tableOfContentsService: ProgrammingTableOfContentsService, activatedRoute: ActivatedRoute) 
  { 
    let appUrl = activatedRoute.snapshot.params["appUrl"];

    let articleBuilder = new ArticleBuilder();
    let appMetadata = tableOfContentsService.getAppMetadata(appUrl);
    if (appMetadata !== undefined && appMetadata !== null)
    {
      this.title = appMetadata["title"];
      articleBuilder.addParagraph(appMetadata["description"]);
      articleBuilder.addSeparator();
      articleBuilder.addEmbeddedProgram(appUrl);
      this.article = articleBuilder.article;
    }
  }

  ngOnInit() {
  }

}
