import { ArticleModel } from '../models/ArticleModel'
import { ArticleBuilder } from '../models/ArticleBuilder'

export class LatexArticleParser
{
    articleBuilder: ArticleBuilder;
    constructor()
    {
        this.articleBuilder = new ArticleBuilder();

    }

    public parseLatex(latexContent: string) : ArticleModel
    {
        latexContent = latexContent.replace(/(\\\\\\\\|\\\[|\\\])/g, '');
        this.articleBuilder.clear();
  
        const splitRegex = /((?:\\section|\\subsection|\\subsubsection)(?:\**){.*})/g;
        const matchRegex = /(\\section|\\subsection|\\subsubsection)(?:\**){(.*)}/g;
 
        var regexResult : RegExpMatchArray;
        regexResult = [...latexContent.split(splitRegex)];
        for (let i = 0; i < regexResult.length; ++i)
        {
            let piece = regexResult[i];
            let matches = [...piece["matchAll"](matchRegex)];
            
            if (matches.length === 0)
            {
                this.parseAndAddEnvironments(piece);
            }
            else
            {
                let command = matches[0][1];
                let content = matches[0][2];

                switch (command)
                {
                    case '\\section':
                        this.articleBuilder.addHeading(content);
                        break;
                    case '\\subsection':
                        this.articleBuilder.addSubheading(content);
                        break;
                    case '\\subsubsection':
                        this.articleBuilder.addSubsubheading(content);
                        break;
                    default:
                        this.articleBuilder.addParagraph(content);
                        console.log("Default called")
                        break;
                }
            }
        }
        return this.articleBuilder.article;
    }

    parseAndAddEnvironments(latexPiece: string) : void
    {
        let environmentSplitRegex = /((?:\\begin{([a-zA-Z]*)})(?:.|\t|\r|\s)*?(?:\\end{\2}))/g;
        let environments = [...latexPiece["split"](environmentSplitRegex)];

        let wasLastTokenAnEnvironment = false;
        for (var i = 0; i < environments.length; ++i)
        {
            let environmentMatchRegex = /((?:\\begin{([a-zA-Z]*)})((?:.|\t|\r|\s)*?)(?:\\end{\2}))/g;
            let potentialEnvironment = environments[i];
            let environmentMatch = [...potentialEnvironment["matchAll"](environmentMatchRegex)];
            
            if (environmentMatch.length === 0)
            {
                if (wasLastTokenAnEnvironment === false)
                {
                    this.articleBuilder.addParagraph(potentialEnvironment);
                }
                else
                {
                    wasLastTokenAnEnvironment = false;
                }
            }
            else
            {
                let environmentName = environmentMatch[0][2];
                let environmentContent = environmentMatch[0][3];
                
                switch (environmentName){
                    case "itemize":
                        this.addList(environmentContent);
                        break;
                    case "code":
                        this.articleBuilder.addCode(environmentContent);
                        break;
                    default:
                        this.articleBuilder.addParagraph(`\\begin{${environmentName}}${environmentContent}\\end{${environmentName}}`)
                        break;
                }
                wasLastTokenAnEnvironment = true;
            }
        }
    }

    addList(latexList: string) : void
    {
        let itemRegex = /(?:\\item{(.*)}|\\item (\s*.*))/g;
        let items1 = [...latexList["matchAll"](itemRegex)]
        let items = items1.map(function (itemMatch)
        {
            if (itemMatch[1] !== undefined)
            {
                return itemMatch[1];
            }
            else
            {
                return itemMatch[2];
            }

        });
        this.articleBuilder.addList(items);
    }

    handleCode(latexCode: string){
        let langRegex = /{(.*)}/g;
    }
}