import { Injectable } from '@angular/core';

import * as TableOfContentsModule from '../assets/math/table-of-contents.json';

@Injectable({
  providedIn: 'root'
})
export class MathTableOfContentsService {
  tableOfContents: Object;
  constructor() 
  { 
    this.tableOfContents = TableOfContentsModule["default"];
  }

  getSubjects() : Array<Object>
  {
    let result = [];
    for (let topicName in this.tableOfContents)
    {
      result.push({ title: this.tableOfContents[topicName].title, route: topicName })
    }
    return result;
  }

  getSubject(subjectName: string) : Object
  {
    if (this.hasSubject(subjectName))
    {
      return this.tableOfContents[subjectName];
    }
    else
    {
      return null;
    }
  }

  hasSubject(subjectName: string): boolean
  {
    return this.tableOfContents[subjectName] !== undefined;
  }

  getTopicForSubject(subjectName: string, topicName: string) : Object
  {
    let subjectObject = this.tableOfContents[subjectName];
    if (subjectObject !== undefined)
    { 
      if (subjectObject["topics"] !== undefined)
      {
        return subjectObject["topics"][topicName];
      }
    }
    else
    {
      return null;
    }
  }

  getTopicsForSubject(subjectName: string): Array<Object>
  {
    let subjectObject = this.tableOfContents[subjectName];
    if (subjectObject !== undefined)
    { 
      let topicsList = [];
      for (let key in subjectObject["topics"])
      {
        topicsList.push({...subjectObject["topics"][key], ...{route: key}});
      }
      return topicsList;
    }
    else
    {
      return null;
    }
  }
}
