<form>
    <div class="align-with-nav">
        <div *ngIf="!(authService.user | async)">
            <h2>Login</h2>

            <input type="text" [(ngModel)]="email" name="Email" placeholder="Email">
            <input type="password" [(ngModel)]="password" name="Password" placeholder="Password">

            <button (click)="login()" [disabled]="!email || !password">
                Login
            </button>
        </div>
        <div *ngIf="(authService.user | async)">
            <h1>Hi, {{ (authService.user | async)?.displayName }}</h1>
            <button (click)="logout()">
                Logout
            </button>
        </div>
      </div>
      
</form>