import { Component, Input, OnInit, Output, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';

import { Image, Folder } from '../note-box/note-box.component'
import { Observable, Subscription } from 'rxjs';

import { faFolder, faChevronRight, IconDefinition } from '@fortawesome/free-solid-svg-icons'

@Component({
  selector: 'app-folder-view, [app-folder-view]',
  templateUrl: './folder-view.component.html',
  styleUrls: ['./folder-view.component.scss']
})
export class FolderViewComponent implements OnInit {  
  private _filesAndFolders: Observable<Array<Image | Folder>> = null;
  private _filesAndFoldersSubscription: Subscription = null;
  public currentItem?: Folder | Image = null;

  @Input() isVisible: boolean = true;
  @Input() isRootLevel: boolean = true;

  @Input() set filesAndFolders(value: Observable<Array<Image | Folder>>)
  {
    if (this._filesAndFoldersSubscription !== null) {
      this._filesAndFoldersSubscription.unsubscribe();
      this._filesAndFoldersSubscription = null;
    }

    this._filesAndFolders = value;
    if (this._filesAndFolders) {
      this._filesAndFoldersSubscription = this.filesAndFolders.subscribe((filesAndFolders) => {
        this.hasOneFolder = filesAndFolders.filter(x => x.type === 'Folder').length > 0;
      })
    }
  }

  get filesAndFolders(): Observable<Array<Image | Folder>> {
    return this._filesAndFolders;
  }

  @Output() get selectedDatabasePath(): string {
    if (!this.currentItem || this.currentItem.type === 'Image') {
      return '/';
    }
    if (this.subdirectoryView) {
      return `/${ this.currentItem.name }/items${ this.subdirectoryView.selectedDatabasePath }`
    }
    else {
      return `/${ this.currentItem.name }`;
    }
  }

  @Output() get selectedStoragePath(): string {
    if (!this.currentItem || this.currentItem.type === 'Image') {
      return '';
    }
    if (this.subdirectoryView) {
      return `${ this.currentItem.name }/${ this.subdirectoryView.selectedStoragePath }`
    }
    else {
      return `${ this.currentItem.name }`;
    }
  }

  @Output() get selectedItem(): Image | Folder {
    if (this.subdirectoryView && this.subdirectoryView.selectedItem) {
      return this.subdirectoryView.selectedItem;
    }
    else {
      return this.currentItem;
    }
  }

  public faFolder: IconDefinition = faFolder;
  public faChevronRight: IconDefinition = faChevronRight;

  public hasOneFolder: boolean = false;

  @ViewChild("subdirectoryView") subdirectoryView: FolderViewComponent;
  @ViewChild("viewTemplate", { static: true }) componentTemplate: TemplateRef<any>;

  constructor() {
   }

  ngOnInit(): void {
  }

  onItemClicked(item: Folder | Image): void {
    this.currentItem = item;
    if (this.subdirectoryView) {
      this.subdirectoryView.clearSelection();
    }
  }

  public clearSelection() {
    this.currentItem = null;
    if (this.subdirectoryView) {
      this.subdirectoryView.clearSelection();
    }
  }
}
