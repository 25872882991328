import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MathComponent } from './math/math.component';
import { ProgrammingComponent } from './programming/programming.component'
import { MathSubjectComponent } from './math-subject/math-subject.component';
import { MathTopicComponent } from './math-topic/math-topic.component';
import { ArticleComponent } from './article/article.component';
import { QtEmbedComponent } from './qt-embed/qt-embed.component';
import { ContactComponent } from './contact/contact.component';
import { ProgramViewComponent } from './program-view/program-view.component';
import { ArticlesPageComponent } from './articles-page/articles-page.component';
import { NoteBoxComponent } from './note-box/note-box.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: "math", component: MathComponent },
  { path: "math/:subject", component: MathSubjectComponent },
  { path: "math/:subject/:topic", component: MathTopicComponent },
  { path: "programming", component: ProgrammingComponent },
  { path: "programming/:appUrl", component: ProgramViewComponent },
  { path: "articles", component: ArticlesPageComponent },
  { path: "articles/:articleName", component: ArticleComponent },
  { path: "contact", component: ContactComponent },
  { path: "qt-embed-test", component: QtEmbedComponent },
  { path: "note-box", component: NoteBoxComponent },
  { path: "login", component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
