import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProgrammingTableOfContentsService } from '../programming-table-of-contents.service';

declare var QtLoader: any;

@Component({
  selector: 'app-qt-embed',
  templateUrl: './qt-embed.component.html',
  styleUrls: ['./qt-embed.component.scss'],
  inputs: []
})
export class QtEmbedComponent implements OnInit {

  @Input() appUrl: string;
  appName: string;
  title: string;
  description: string;
  repoLink: string;
  tableOfContentsService: ProgrammingTableOfContentsService

  constructor(activeRoute: ActivatedRoute, tableOfContentsService: ProgrammingTableOfContentsService)
  { 
    this.tableOfContentsService = tableOfContentsService;
    if (this.appName === undefined || this.appName === '')
    {
      this.appUrl = activeRoute.snapshot.params["appUrl"];
    }
  }

  ngOnInit() {
    
      let appMetadata = this.tableOfContentsService.getAppMetadata(this.appUrl);
      if (appMetadata !== undefined && appMetadata !== null)
      {
        this.appName = appMetadata["qtAppName"];
        this.title = appMetadata["title"];
        this.description = appMetadata["description"];
        this.repoLink = appMetadata["repoLink"];
      }

    var spinner = document.querySelector('#qtspinner') as HTMLElement;
    var canvas = document.querySelector('#qtcanvas') as HTMLElement;
    var status = document.querySelector('#qtstatus') as HTMLElement;

    var qtLoader = QtLoader({
      path: `assets/programming/${this.appUrl}`,
      canvasElements: [canvas],
      showLoader: function(loaderStatus) {
          spinner.style.display = 'block';
          canvas.style.display = 'none';
          status.innerHTML = loaderStatus + "...";
      },
      showError: function(errorText) {
          status.innerHTML = errorText;
          spinner.style.display = 'block';
          canvas.style.display = 'none';
      },
      showExit: function() {
          status.innerHTML = "Application exit";
          if (qtLoader.exitCode !== undefined)
              status.innerHTML += " with code " + qtLoader.exitCode;
          if (qtLoader.exitText !== undefined)
              status.innerHTML += " (" + qtLoader.exitText + ")";
          spinner.style.display = 'block';
          canvas.style.display = 'none';
      },
      showCanvas: function() {
          spinner.style.display = 'none';
          canvas.style.display = 'block';
      },
    });
    qtLoader.loadEmscriptenModule(`${ this.appName }`);
  }
}
