<div class="align-with-nav" id="about">
    <h1 class="title-heading">Programming</h1>
    <p>
        I'm a lifelong developer. I got my start in my teens and have been hooked on programming ever since. I got my start with the XNA framework for C#, which was used for making independent games on the Xbox 360. 
    </p>
    <p>
        After that, I started to learn Objective-C for the purpose of iPhone app programming. I wrote my first game, called Heli Madness, and released it in the spring of 2011. It was a simple game that let players dodge airplanes as a helicopter using the built in gyroscope. It was similar to Doodle Jump, but without any gravity.  
    </p>
    <p>
        Thereafter, I started learning C++ and used it for multiple hobby projects, including web-browsers, encryption schemes, and other miscellaneous GUI projects using <a href="https://www.qt.io">Qt</a>. I have worked on contracts within a diverse set of companies involved in finance, solar, and aerospace.
    </p>
</div>
<div class="trendy-gradient-bar-that-everyone-is-in-love-with"></div>
<div class="featured">
    <h1 class="align-with-nav">Featured</h1>
    <div class="slide-outer align-with-nav">
        <div class="featured-slide" id="announcement-slide">
            <h2>Neural Network</h2>
            <p>A homebrew neural network implemented in C++20 with support for constant expressions.<a class="button" [routerLink]="['../articles/neural-network']">Find out more!</a></p>
        </div>
    </div>
</div>
<div class="trendy-gradient-bar-that-everyone-is-in-love-with-dt"></div>
<div></div>
<div class="hero">
    <div class="align-with-nav">
        <div class="programs-container">
            <h2 class="title-heading">All Applications</h2>
            <ul>
                <li *ngFor='let program of programs'><a [routerLink]="[program.route]">{{ program.title }}</a></li>
            </ul>
        </div>
    </div>
</div>