import { Component, OnInit, setTestabilityGetter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MathTableOfContentsService } from '../math-table-of-contents-service.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-math-subject',
  templateUrl: './math-subject.component.html',
  styleUrls: ['../math/math.component.scss', './math-subject.component.scss']
})
export class MathSubjectComponent implements OnInit {

  subject: string;
  title: string;
  description: string;
  topicsList: Array<Object>;

  constructor(mathTableOfContentsService: MathTableOfContentsService, activatedRoute: ActivatedRoute, title: Title) 
  { 
    activatedRoute.paramMap.subscribe(params => { 
      this.subject = params.get('subject'); 
      if (mathTableOfContentsService.hasSubject(this.subject))
      {
        let subjectObject = mathTableOfContentsService.getSubject(this.subject);
        this.title = subjectObject["title"];
        this.description = subjectObject["description"];
        this.topicsList = mathTableOfContentsService.getTopicsForSubject(this.subject)
        title.setTitle(`Austin Simpson - Math - ${ this.title }`)
      }
      else
      {
        title.setTitle("Austin Simpson - Not found")
      }
  });

  }

  ngOnInit() {
  }

}
