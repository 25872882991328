import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/compat/auth'
import { User } from '@angular/fire/auth'
import * as firebase from 'firebase/app'

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  user: Observable<User>

  constructor(public fireAuth: AngularFireAuth) { 
    this.user = fireAuth.authState;
  }

  public loginWithEmailAndPassword(email: string, password: string) {
    this.fireAuth.signInWithEmailAndPassword(email, password).then(() => {
      console.log("Login successful")
    }).catch(() => {
      console.log("Login failed")
    })
  }

  public logout() {
    this.fireAuth.signOut()
  }
}
