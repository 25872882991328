import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PartialObserver } from 'rxjs';

import * as TableOfContentsModule from '../assets/articles/table-of-contents.json';
import { ArticleModel } from './models/ArticleModel.js';

@Injectable({
  providedIn: 'root'
})
export class ArticlesTableOfContentsService 
{
  tableOfContents: Object;
  httpClient: HttpClient;

  constructor(http: HttpClient) 
  {
    this.tableOfContents = TableOfContentsModule["default"];
    this.httpClient = http;
  }

  getAll(): Array<ArticleModel>
  {
    let result = new Array<ArticleModel>();
    for (let index in this.tableOfContents)
    {
      result.push({...this.tableOfContents[index], "link": index})
    }
    return result;
  }

  getArticle(articleName: string, handler: any)
  {
    let articleMetadata = this.tableOfContents[articleName];
    if (articleMetadata !== undefined) 
    {
        let fileName = articleMetadata['fileName'];
        let fullUri = `assets/articles/${articleName}/${fileName}`
        this.httpClient.get<ArticleModel>(fullUri).subscribe(handler);
    }
  }
}
