import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HomeComponent } from './home/home.component';
import { MathComponent } from './math/math.component';
import { ProgrammingComponent } from './programming/programming.component';
import { MathSubjectComponent } from './math-subject/math-subject.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MathTopicComponent } from './math-topic/math-topic.component';
import { ArticleComponent } from './article/article.component';
import { PrismHighlightDirective } from './prism-highlight.directive';
import { QtEmbedComponent } from './qt-embed/qt-embed.component';
import { ContactComponent } from './contact/contact.component';
import { ProgramViewComponent } from './program-view/program-view.component';
import { ArticlesPageComponent } from './articles-page/articles-page.component';
import { DisplayMathDirective } from './display-math.directive';
import { NoteBoxComponent } from './note-box/note-box.component'


import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule } from '@angular/fire/compat/auth'
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'
import { LoginComponent } from './login/login.component'
import { FormsModule } from '@angular/forms';
import { ImageEditorComponent } from './image-editor/image-editor.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FolderViewComponent } from './folder-view/folder-view.component';



declare global {
  interface Window { MathJax: any; }
}


@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    MathComponent,
    ProgrammingComponent,
    MathSubjectComponent,
    NotFoundComponent,
    MathTopicComponent,
    ArticleComponent,
    PrismHighlightDirective,
    QtEmbedComponent,
    ContactComponent,
    ProgramViewComponent,
    ArticlesPageComponent,
    DisplayMathDirective,
    NoteBoxComponent,
    LoginComponent,
    ImageEditorComponent,
    FolderViewComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp({
      apiKey: "AIzaSyCnk10eGojmurZQlD4MSEtn9o6CVlb_H8s",
      authDomain: "portfolio-3aade.firebaseapp.com",
      storageBucket: "portfolio-3aade.appspot.com",
      projectId: "portfolio-3aade"      
    }),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    FormsModule,
    FontAwesomeModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
