import { Component, OnInit } from '@angular/core';
import { MathTableOfContentsService } from '../math-table-of-contents-service.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { LatexArticleParser } from '../models/LatexArticleParser';
import { ArticleModel } from '../models/ArticleModel';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-math-topic',
  templateUrl: './math-topic.component.html',
  styleUrls: ['../math/math.component.scss', './math-topic.component.scss']
})

export class MathTopicComponent implements OnInit {
  subject: string;
  subjectTitle: string;

  topic: string;
  title: string;
  description: string;

  parser: LatexArticleParser;
  article: ArticleModel;
  constructor(_mathTableOfContentsService: MathTableOfContentsService, _activatedRoute: ActivatedRoute, http: HttpClient, title: Title) 
  {
    this.article = new ArticleModel();
    this.subject = _activatedRoute.snapshot.params["subject"];
    this.subjectTitle = _mathTableOfContentsService.getSubject(this.subject)["title"];
    
    this.topic = _activatedRoute.snapshot.params["topic"];
    let topicObject = _mathTableOfContentsService.getTopicForSubject(this.subject, this.topic);
    if (topicObject !== null && topicObject !== undefined)
    {
      this.title = topicObject["title"];
      this.description = topicObject["description"];
      let fileName = topicObject["fileName"];

      title.setTitle(`Austin Simpson - Math - ${ this.subjectTitle } - ${ this.title }`);
      
      this.parser = new LatexArticleParser();
      http.get(`assets/math/${this.subject}/${fileName}`, { responseType: 'text' as 'json'}).subscribe(data => {
        this.article = this.parser.parseLatex(data as string);
    })
    }
  }

  ngOnInit() {
  }

}
