<div id="home">
    <div class="hero" id="first-hero">
        <div class="align-with-nav">
            <h1>Welcome!</h1>
            <p class="bottomize">This is my page for showcasing the projects that I work on. Please feel free to look around.</p>
        </div>
    </div>
    <div class="hero">
        <div class="trendy-gradient-bar-that-everyone-is-in-love-with-dt"></div>
        <div class="align-with-nav">
            <h1>Programming</h1>
            <p>
                I got my start in software development as an early teenager. My journey began with animating simple powerpoint presentations. Later, it evolved into writing my first game in C# with the XNA framework from Microsoft.
                Later, I wrote my first iPhone app, Heli Madness, and published it on the iOS App Store.
            </p>
            <a class="button" [routerLink]="['programming']">Check out my awesome projects!</a>
        </div>
    </div>
    <div class="hero">
        <div class="trendy-gradient-bar-that-everyone-is-in-love-with"></div>
        <div class="align-with-nav">
            <h1>Mathematics</h1>
            <p>
                I have always had a penchant for mathematics. I always enjoyed learning new ways to solve problems. My interest started as a side effect of my hobby in writting games. Most of the mathematics involved is trigonometric or linear algebra.
                At the start of my college career, I fell in love with calculus. It enabled me to solve mind blowing problems, "like how much work does it take to send a rocket into outer space?" (using improper integrals).
            </p>
            <a class="button" [routerLink]='["math"]'>View some rigorous proofs!</a>
        </div>
    </div>
</div>