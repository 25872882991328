
import { Component, ElementRef, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';

import { Gif, GifUtil, GifCodec, GifFrame, BitmapImage, GifError } from 'gifwrap'

import Jimp from 'jimp/browser/lib/jimp';

interface Point2d {
  x: number;
  y: number
}

interface Rectangle {
  topLeft: Point2d;
  width: number;
  height: number;
}

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit, AfterViewInit {
  image?: Jimp;
  gif?: Gif;

  imageResizedForCanvas?: Jimp;
  private renderImageData?: ImageData;
  @ViewChild("imageCanvas") canvasElement: ElementRef<HTMLCanvasElement>
  canvasContext: CanvasRenderingContext2D;

  dragStart?: Point2d;
  dragEnd?: Point2d

  dragHandleRadius: number = 5;
  isCurrentlyDragging: boolean = false;

  constructor() { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    this.canvasContext = this.canvasElement.nativeElement.getContext("2d");
  }

  @HostListener("mousedown", ['$event'])
  private onMouseDown(event: MouseEvent)
  {
    const boundingRect = this.canvasElement.nativeElement.getBoundingClientRect();

    if (this.dragStart && this.dragEnd) {
      const selection = this.getSelectionFromDragPoints(this.dragStart, this.dragEnd);
      const dragHandleCenter: Point2d = {
        x: selection.topLeft.x + selection.width / 2,
        y: selection.topLeft.y + selection.height / 2
      }
      const localMousePos: Point2d = { 
        x: event.clientX - boundingRect.left,
        y: event.clientY - boundingRect.top
      }

      if (Math.pow(localMousePos.x - dragHandleCenter.x, 2) + Math.pow(localMousePos.y - dragHandleCenter.y, 2) < Math.pow(this.dragHandleRadius, 2)) {
        this.isCurrentlyDragging = true;
      }
      else {
        this.dragStart =  {
          x: event.clientX - boundingRect.left,
          y: event.clientY - boundingRect.top
        }
        this.dragEnd = null;
      }
    }
    else {
      this.dragStart =  {
        x: event.clientX - boundingRect.left,
        y: event.clientY - boundingRect.top
      }
      this.dragEnd = null;
    }
  }

  @HostListener("mousemove", ['$event'])
  private onMouseMove(event: MouseEvent)
  {
    const boundingRect = this.canvasElement.nativeElement.getBoundingClientRect();

    const localMousePos: Point2d = { 
      x: event.clientX - boundingRect.left,
      y: event.clientY - boundingRect.top
    }

    this.canvasElement.nativeElement.style.cursor = "crosshair";
    if (this.dragStart && this.dragEnd) {
      const selection = this.getSelectionFromDragPoints(this.dragStart, this.dragEnd);
      const dragHandleCenter: Point2d = {
        x: selection.topLeft.x + selection.width / 2,
        y: selection.topLeft.y + selection.height / 2
      }
      if (Math.pow(localMousePos.x - dragHandleCenter.x, 2) + Math.pow(localMousePos.y - dragHandleCenter.y, 2) < Math.pow(this.dragHandleRadius, 2)) {
        this.canvasElement.nativeElement.style.cursor = "grab";
      }

      if (this.isCurrentlyDragging && event.buttons === 1) {
        this.dragStart = {
          x: localMousePos.x - selection.width / 2,
          y: localMousePos.y - selection.height / 2
        }
        this.dragEnd = {
          x: localMousePos.x + selection.width / 2,
          y: localMousePos.y + selection.height / 2
        }       
      }
    }

    if (!this.isCurrentlyDragging && event.buttons === 1)
    {
      this.dragEnd = localMousePos;
    }
    
    this.render();
  }

  @HostListener("mouseup", ['$event'])
  private onMouseUp(event: MouseEvent) {
    this.isCurrentlyDragging = false;
    this.render();
  }

  private cropImage(image: Jimp): Jimp {

    const canvasWidth = this.canvasElement.nativeElement.width;
    const canvasHeight = this.canvasElement.nativeElement.height;
    const size = Math.min(canvasWidth, canvasHeight);
    const topLeft = this.dragStart ? this.dragStart : { x: canvasWidth / 2 - size / 2, y: canvasHeight / 2 - size / 2 }
    const bottomRight = this.dragEnd ? this.dragEnd : { x: canvasWidth / 2 + size / 2, y: canvasHeight / 2 + size / 2 }
    const selection = this.getSelectionFromDragPoints(topLeft, bottomRight);
    const imageTopLeft = this.canvasPointToImageCoordinate(selection.topLeft);
    const imageBottomRight = this.canvasPointToImageCoordinate({x: selection.topLeft.x + selection.width, y: selection.topLeft.y + selection.height})
    console.log(image.getWidth(), image.getHeight())
    console.log({imageTopLeft, imageBottomRight, width: imageBottomRight.x - imageTopLeft.x, height: imageBottomRight.y - imageTopLeft.y})
    return image.crop(imageTopLeft.x, imageTopLeft.y, imageBottomRight.x - imageTopLeft.x, imageBottomRight.y - imageTopLeft.y);
  }

  public async getBuffer(makeWhiteTransparent: boolean = false): Promise<Buffer> {
    if (this.gif === null) {
      const resizedImage = this.cropImage(this.image).resize(32, 32);
      if (makeWhiteTransparent) {
        this.makeWhiteTransparent(resizedImage);
      }
      return await resizedImage.getBufferAsync(Jimp.MIME_PNG);
    }
    else {
      const resizedGif = await this.resizeGif(this.gif);
      return resizedGif.buffer;
    }
  }

  async readFromBuffer(buffer: Buffer): Promise<void> {
    const image = await Jimp.read(buffer); 
    console.log(image);
    if (image.getMIME() !== 'image/gif') {
      this.image = image;
      this.gif = null;
    }
    else { 
      this.gif = await GifUtil.read(buffer);
      if (this.gif.frames.length > 0)
      {
        this.image = GifUtil.copyAsJimp(Jimp, this.gif.frames[0]) as Jimp;
      }
      else {
        return;
      }
    }

    this.dragStart = null;
    this.dragEnd = null;

    const canvasWidth = this.canvasElement.nativeElement.width;
    const canvasHeight = this.canvasElement.nativeElement.height;
  
    const scale = Math.min(canvasWidth / this.image.bitmap.width, canvasHeight / this.image.bitmap.height);
    
    this.imageResizedForCanvas = new Jimp(this.image).resize(this.image.bitmap.width * scale, this.image.bitmap.height * scale, Jimp.RESIZE_BICUBIC)
    this.renderImageData = new ImageData(Uint8ClampedArray.from(this.imageResizedForCanvas.bitmap.data), this.imageResizedForCanvas.bitmap.width, this.imageResizedForCanvas.bitmap.height);
    this.render();
    
  }

  private render() {
    this.canvasContext.imageSmoothingEnabled = false;

    const canvasWidth = this.canvasElement.nativeElement.width;
    const canvasHeight = this.canvasElement.nativeElement.height;

    this.canvasContext.fillStyle = "#000000";
    this.canvasContext.fillRect(0, 0, canvasWidth, canvasHeight);
    this.renderImage();

    if (this.dragStart && this.dragEnd) {
      this.renderCropBox();
      this.renderDragHandle();
    }
  }

  private renderImage() {

    const canvasWidth = this.canvasElement.nativeElement.width;
    const canvasHeight = this.canvasElement.nativeElement.height;
  
    const x = (canvasWidth / 2) - this.renderImageData.width / 2 ;
    const y = (canvasHeight / 2) - this.renderImageData.height / 2;
    this.canvasContext.putImageData(this.renderImageData, x, y);
  }

  private renderCropBox() {
    const selection = this.getSelectionFromDragPoints(this.dragStart, this.dragEnd);
    const canvasWidth = this.canvasElement.nativeElement.width;
    const canvasHeight = this.canvasElement.nativeElement.height;

    this.canvasContext.fillStyle = "#00000033"
    this.canvasContext.fillRect(0, 0, selection.topLeft.x, canvasHeight);
    this.canvasContext.fillRect(selection.topLeft.x + selection.width, 0, canvasWidth - selection.topLeft.x - selection.width, canvasHeight);
    this.canvasContext.fillRect(selection.topLeft.x, 0, selection.width, selection.topLeft.y);
    this.canvasContext.fillRect(selection.topLeft.x, selection.topLeft.y + selection.height, selection.width, canvasHeight - selection.topLeft.y - selection.height);

    this.canvasContext.strokeStyle = "#444444";
    this.canvasContext.lineWidth = 1;
    this.canvasContext.setLineDash([5, 15]);
    this.canvasContext.strokeRect(selection.topLeft.x, selection.topLeft.y, selection.width, selection.height);
  }

  private renderDragHandle() {
    const selection = this.getSelectionFromDragPoints(this.dragStart, this.dragEnd);
    const center: Point2d = {
      x: selection.topLeft.x + selection.width / 2,
      y: selection.topLeft.y + selection.height / 2
    }

    this.canvasContext.fillStyle = "#000000"
    this.canvasContext.strokeStyle = "#000000"
    this.canvasContext.beginPath();
    this.canvasContext.arc(center.x, center.y, this.dragHandleRadius, 0, Math.PI * 2, false);
    this.canvasContext.setLineDash([]);
    this.canvasContext.stroke();
  }

  private canvasPointToImageCoordinate(point: Point2d) {
    const canvasWidth = this.canvasElement.nativeElement.width;
    const canvasHeight = this.canvasElement.nativeElement.height;

    const scale = Math.min(canvasWidth / this.image.bitmap.width, canvasHeight / this.image.bitmap.height);
    const x = (canvasWidth / 2) - this.renderImageData.width / 2 ;
    const y = (canvasHeight / 2) - this.renderImageData.height / 2;
    return {
      x: Math.round((point.x - x) / scale),
      y: Math.round((point.y - y) / scale)
    }
  }

  private getSelectionFromDragPoints(dragStart: Point2d, dragEnd: Point2d): Rectangle {
    let size = Math.min(Math.abs(dragEnd.x - dragStart.x), Math.abs(dragEnd.y - dragStart.y))
    let left = dragStart.x < dragEnd.x ? dragStart.x : dragStart.x - size;
    let top = dragStart.y < dragEnd.y ? dragStart.y : dragStart.y - size;

    const canvasWidth = this.canvasElement.nativeElement.width;
    const canvasHeight = this.canvasElement.nativeElement.height;

    const letterBoxX = (canvasWidth / 2) - this.renderImageData.width / 2 ;
    const letterBoxY = (canvasHeight / 2) - this.renderImageData.height / 2;

    if (left < letterBoxX) {
      left = letterBoxX;
    }
    if (left + size > letterBoxX + this.renderImageData.width) {
      left = letterBoxX + this.renderImageData.width - size
    }

    if (top < letterBoxY) {
      top = letterBoxY;
    }
    if (top + size > letterBoxY + this.renderImageData.height) {
      top = letterBoxY + this.renderImageData.height - size
    }

    return {
      topLeft: {
        x: left,
        y: top
      },
      width: size,
      height: size
    }
  }

  public clearImage(): void {
    this.image = null;
    this.gif = null;
    this.imageResizedForCanvas = null;
    this.renderImageData = null;
  }

  private makeWhiteTransparent(image: Jimp) {
    for (let x = 0; x < image.getWidth(); ++x) {
      for (let y = 0; y < image.getHeight(); ++y) {
        const colorNumber: number = image.getPixelColor(x, y);
        const colorRgb = Jimp.intToRGBA(colorNumber);
        const brightness: number = (0.2126 * colorRgb.r) + (0.7152 * colorRgb.g) + (0.0722 * colorRgb.b);
        if (brightness > 200) {
          image.setPixelColor(0, x, y);
        }
      }
    }
  }

  private async resizeGif(gif: Gif, makeWhiteTransparent: boolean = true): Promise<Gif> {
    const gifWidth = gif.width;
    const gifHeight = gif.height;

    const frames = gif.frames.map((frame: GifFrame, index: number) => {
      
      const newFrameWidth = frame.bitmap.width / gifWidth * 32;
      const newFrameHeight = frame.bitmap.height / gifHeight * 32;
      
      const frameAsJimp = GifUtil.copyAsJimp(Jimp, frame);
      const frameCroppedJimp = frameAsJimp.resize(newFrameWidth, newFrameHeight);
      if (makeWhiteTransparent) {
        this.makeWhiteTransparent(frameCroppedJimp);
      }
      let bitmapImage = new BitmapImage(frameCroppedJimp.bitmap);
      GifUtil.quantizeWu(bitmapImage, 255);
      const frameSettings = {
        delayCentisecs: frame.delayCentisecs,
        disposalMethod: frame.disposalMethod,
        isInterlaced: frame.interlaced,
        xOffset: Math.round((frame.xOffset / this.gif.width) * 32),
        yOffset: Math.round((frame.yOffset / this.gif.height) * 32)
      };
      const resizedFrame = new GifFrame(bitmapImage, frameSettings);
      console.log({ originalFrame: frame, resizedFrame })
      return resizedFrame;
    })
    const codec = new GifCodec();
 
    try {
      return await codec.encodeGif(frames, { loops: this.gif.loops });
    }
    catch (error){
      if (error as GifError)
      {
        console.log(error)
      }
    }
  }

}
