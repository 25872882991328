import { Injectable } from '@angular/core';

import * as TableOfContentsModule from '../assets/programming/table-of-contents.json'

@Injectable({
  providedIn: 'root'
})
export class ProgrammingTableOfContentsService 
{
  tableOfContents: Object;
  constructor() { 
    this.tableOfContents = TableOfContentsModule["default"]; 
  }

  getAppMetadata(appName: string)
  {
    return this.tableOfContents[appName];
  }

  getAllPrograms() : Array<Object>
  {
    let result = [];
    for (let programName in this.tableOfContents)
    {
      result.push({ title: this.tableOfContents[programName].title, route: programName })
    }
    return result;
  }
}
