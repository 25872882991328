import { Directive, ElementRef } from '@angular/core';

import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import 'prismjs';
import 'prismjs/plugins/toolbar/prism-toolbar';
import 'prismjs/plugins/highlight-keywords/prism-highlight-keywords';
import 'prismjs/components/prism-python';
import 'prismjs/components/prism-latex';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-typescript';
import 'prismjs/components/prism-bash';

declare var Prism: any;

@Directive({
  selector: '[appPrismHighlight]'
})

export class PrismHighlightDirective {

  constructor( @Inject(PLATFORM_ID) private platformId: Object, element: ElementRef) 
  {
      setTimeout(() => {Prism.highlightElement(element.nativeElement)}, 500);
  }
}
