<div class="align-with-nav" id="about">
    <h1 class="title-heading">Mathematics</h1>
    <p>
        I persued my undergraduate degree in mathematics. I studied many different areas of mathematics, such as mathematical modeling, real and complex analysis, algebraic structures, and much more. Hence, this page is meant to showcase my abilities in analytical thinking and writing clear, concise proofs.
    </p>
    <p>
        You will find selected exercises from my coursework below.
    </p>
</div>
<div class="trendy-gradient-bar-that-everyone-is-in-love-with"></div>
<div class="subjects-container">
    <div class="align-with-nav">
        <h1 class="underlined-heading">Subjects</h1>
        <ul>
            <li *ngFor='let subject of this.subjects'><a [routerLink]='subject.route'>{{ subject.title }}</a></li>
        </ul>
    </div>
</div>
