<div class="align-with-nav">
    <h1 class="title-heading">{{ this.title }}</h1>
    <ul class="light-nav">
        <li><a [routerLink]='"../"'>Math</a></li>
    </ul>
    <p>{{ this.description }}</p>
</div>
<div class="trendy-gradient-bar-that-everyone-is-in-love-with"></div>
<div id="topics-container">
    <div class="align-with-nav">
        <h1 class="underlined-heading">Topics</h1>
    </div>
    <div class="topic" *ngFor="let topic of this.topicsList; index as i">
        <div class="align-with-nav inner">
            <h3>{{ topic.title }}</h3>
            <p>{{ topic.description }}</p>
            
            <a [routerLink]="topic.route" class="button">See More</a> 
        </div>
    </div>
</div>