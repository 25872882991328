import { Component, OnInit } from '@angular/core';
import { ProgrammingTableOfContentsService } from '../programming-table-of-contents.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-programming',
  templateUrl: './programming.component.html',
  styleUrls: ['./programming.component.scss']
})
export class ProgrammingComponent implements OnInit {

  programs: Array<Object>
  constructor(programTableOfContents: ProgrammingTableOfContentsService, title: Title) 
  {
    this.programs = programTableOfContents.getAllPrograms();
    title.setTitle("Austin Simpson - Programming")
  }

  ngOnInit() {
  }

}
