export enum ArticleNodeType
{
    Heading = "Heading", 
    Subheading = "Subheading", 
    Subsubheading = "Subsubheading",
    Paragraph = "Paragraph",
    Code = "Code",
    List = "List",
    EmbeddedProgram = "EmbeddedProgram",
    Separator = "Separator",
    Link = "Link",
    Image = "Image",
    Video = "Video",
    Table = "Table"
}

export class ArticleNode
{
    type: ArticleNodeType;
    content: string; 
    href: string;
    language: string; //Only used when the type is Code
    children: Array<string | Array<string>>; //Only used when the type is list or table
    format: string; //Only used when type is Video
    headings: Array<string>;
    constructor()
    {
        this.children = new Array<string>();
        this.language = "py";
        this.headings = new Array<string>();
    }
}

export class ArticleModel
{
    nodes: Array<ArticleNode>;
    date: string;
    title: string;
    description: string;
    constructor()
    {
        this.date = "";
        this.nodes = new Array<ArticleNode>();
        this.title = "";
        this.description = "";
    }
}