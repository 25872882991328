<ng-container appDisplayMath>
    <h1 class="align-with-nav" *ngIf='this.articleModel.title !== undefined'>{{ articleModel.title }}</h1>
    <div class="align-with-nav" *ngIf='this.articleModel.date !== undefined && this.articleModel.date !== ""'>
        <small>Written by Austin Simpson on {{ articleModel.date }} </small>
    </div>
    <ng-container *ngFor='let articleNode of this.articleModel.nodes' [ngSwitch]="articleNode.type">
        <h1 class="align-with-nav" *ngSwitchCase='ArticleNodeType.Heading'> {{ articleNode.content }}</h1>
        <h2 class="align-with-nav" *ngSwitchCase='ArticleNodeType.Subheading'> {{ articleNode.content }}</h2>
        <h3 class="align-with-nav" *ngSwitchCase='ArticleNodeType.Subsubheading'> {{ articleNode.content }}</h3>
        <p class="align-with-nav" *ngSwitchCase='ArticleNodeType.Paragraph'> {{ articleNode.content }} </p>
        <div class="align-with-nav">
            <pre *ngSwitchCase='ArticleNodeType.Code' appPrismHighlight><code [className]='"language-"+ articleNode.language'>{{ articleNode.content}}</code></pre>
        </div>
        <ul class="article-list align-with-nav" *ngSwitchCase='ArticleNodeType.List'>
            <li *ngFor='let listItem of articleNode.children'> {{ listItem }}</li>
        </ul>
        <div class="hero" *ngSwitchCase='ArticleNodeType.EmbeddedProgram'>
            <div class="align-with-nav">
                <app-qt-embed [appUrl]='articleNode.content'></app-qt-embed>
            </div>
        </div>
        <div class="trendy-gradient-bar-that-everyone-is-in-love-with" *ngSwitchCase='ArticleNodeType.Separator'></div>
        <div class="align-with-nav" *ngSwitchCase="ArticleNodeType.Link"><a target="_blank" rel="noopener" [href]='articleNode.href'>{{ articleNode.content }}</a></div>
        <div class="align-with-nav" *ngSwitchCase="ArticleNodeType.Image">
            <img src={{articleNode.content}} *ngSwitchCase="ArticleNodeType.Image"/>
            <p class="image-caption">{{articleNode.caption}}</p>
        </div>
        <div class="align-with-nav" *ngSwitchCase="ArticleNodeType.Video">
            <video controls>
                <source src={{articleNode.content}} type={{articleNode.format}} />
            </video>
        </div>

        <div *ngSwitchCase="ArticleNodeType.Table">
            <table class="table align-with-nav" >
                <thead>
                    <th *ngFor='let heading of articleNode.headings'>{{ heading }}</th>
                </thead>
                <tbody>
                    <tr *ngFor='let row of articleNode.children'>
                        <td *ngFor='let tableData of row'> {{ tableData }}</td>
                    </tr>
                </tbody>
            </table>
            <p class="image-caption">{{ articleNode.caption }}</p>
        </div>
    </ng-container>
</ng-container>