import { Component, OnInit } from '@angular/core';
import { MathTableOfContentsService } from '../math-table-of-contents-service.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-math',
  templateUrl: './math.component.html',
  styleUrls: ['./math.component.scss']
})
export class MathComponent implements OnInit
{
  subjects: Array<Object>;
  constructor(mathTableOfContentsService: MathTableOfContentsService, title: Title) 
  { 
    this.subjects = mathTableOfContentsService.getSubjects();
    title.setTitle("Austin Simpson - Math")
  }

  ngOnInit() {
  }

}
