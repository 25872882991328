import { Component, OnInit, ViewChild } from '@angular/core';
import { NgModel } from "@angular/forms";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { AngularFireStorage, AngularFireStorageReference } from '@angular/fire/compat/storage';
import { AuthorizationService } from '../authorization.service';
import { ImageEditorComponent } from '../image-editor/image-editor.component';

import { Observable } from 'rxjs';
import { map, last } from 'rxjs/operators';

import Jimp from 'jimp/browser/lib/jimp'
import { FolderViewComponent } from '../folder-view/folder-view.component';
import { runInThisContext } from 'vm';

interface ImageData {
  type: "Image";
  name: string;
  storagePath: string;
  isActive: boolean;
}

export interface Image extends ImageData {
  id: string;
  databasePath: string;
  downloadUrl: Observable<string>;
}

interface FolderData {
  type: "Folder";
  name: string;
  storagePath: string;
  isActive: boolean;
}

export interface Folder extends FolderData {
  id: string;
  databasePath: string;
  items: Observable<Array<Image | Folder>>;
}

export enum AddMode {
  Image = 0,
  Folder = 1
}

@Component({
  selector: 'app-note-box',
  templateUrl: './note-box.component.html',
  styleUrls: ['./note-box.component.scss']
})
export class NoteBoxComponent implements OnInit {
  public noteboxFirestoreCollectionId: string = "notebox"
  public noteboxStorageCollectionId: string = "/note-box-icons"
  private filesAndFoldersCollection: AngularFirestoreCollection<ImageData | FolderData> 
  public filesAndFolders: Observable<Array<Image | Folder>>;
  public fileStorageRef: AngularFireStorageReference;
  public newFileName: string = "";
  public errorText: string = "";
  public hasImage: boolean = false;

  public makeWhiteTransparent: boolean = true;

  public newFolderName: string = "";
  public folderAddErrorText: string = "";

  public addMode: AddMode = AddMode.Image;
  public AddMode: typeof AddMode = AddMode;

  @ViewChild("imageEditor") imageEditorComponent: ImageEditorComponent
  @ViewChild("filesAndFoldersView") filesAndFoldersView: FolderViewComponent;

  constructor(public authService: AuthorizationService, public storageService: AngularFireStorage, public databaseService: AngularFirestore) {
    this.fileStorageRef = storageService.ref(this.noteboxStorageCollectionId);
    this.filesAndFoldersCollection = databaseService.collection(this.noteboxFirestoreCollectionId);
    this.filesAndFolders = this.getImagesAndFolders(this.filesAndFoldersCollection);
  }

  ngOnInit() {
    
  }

  private getImagesAndFolders(storageCollection: AngularFirestoreCollection<ImageData | FolderData>): Observable<Array<Image | Folder>> {
    return storageCollection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = { ...a.payload.doc.data() as ImageData | FolderData };
        if (data.type === "Image") {
          return {
            ...data as ImageData,
            id: a.payload.doc.id,
            databasePath: a.payload.doc.ref.path,
            downloadUrl: this.storageService.ref(data["storagePath"]).getDownloadURL()
          }
        }
        else {
          const subfoldersRef = this.databaseService.collection<ImageData | FolderData>(`${ storageCollection.ref.path }/${ a.payload.doc.id }/items`)
          return {
            ...data as FolderData,
            id: a.payload.doc.id,
            databasePath: a.payload.doc.ref.path,
            items: this.getImagesAndFolders(subfoldersRef)
          }
        }
      }))
    );
  }

  upload(event: Event) {
    const element = event.currentTarget as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      const first = fileList.item(0);
      this.newFileName = first.name;
      first.arrayBuffer().then((buffer: ArrayBuffer) => {
        this.imageEditorComponent.readFromBuffer(Buffer.from(buffer))
        this.hasImage = true;
      })
    }
  }

  addFolder(): void {
    const selectedPath = this.filesAndFoldersView.selectedDatabasePath;
    const newFolderRef = this.filesAndFoldersCollection.doc(`${selectedPath}${this.newFolderName}`);
    newFolderRef.get().subscribe(snapshot => {
      if (snapshot.exists === false) {
        const folder: FolderData = {
          type: "Folder",
          name: this.newFolderName,
          isActive: true,
          storagePath: "",
        }
        newFolderRef.set(folder).then(() => {
          this.newFolderName = "";
        })
      }
      else {
        this.folderAddErrorText = `Folder ${ this.newFolderName } already exists.`;
      }
    })
  }

  async submitNewFile() {
    const fileUpload = document.getElementById("new-icon-upload") as HTMLInputElement;
    const file = fileUpload.files[0];

    const buffer = await this.imageEditorComponent.getBuffer(this.makeWhiteTransparent);

    if (buffer) {
      const refId: string = `${this.filesAndFoldersView.selectedStoragePath}${this.newFileName}`

      let newFileRef = this.fileStorageRef.child(refId);
      newFileRef.put(buffer).then((uploadSnapshot) => { 
        console.log(this.filesAndFoldersView.selectedDatabasePath);
        this.databaseService.collection(`${this.noteboxFirestoreCollectionId}${this.filesAndFoldersView.selectedDatabasePath}`).add({
          name: file.name,
          storagePath: `${this.noteboxStorageCollectionId}/${refId}`,
          type: 'Image',
          isActive: true
        });
      }).catch(() => {
        this.errorText = "Failed to upload file."
      }).finally(() => {
        this.imageEditorComponent.clearImage();
        this.hasImage = false;
        this.newFileName = "";
      });
    }
  }

  onAddModeChanged(value: string) {
    const valueAsInt = parseInt(value);
    if (valueAsInt === 0) {
      this.addMode = this.AddMode.Image
    }
    else {
      this.addMode = this.AddMode.Folder;
    }
  }

  setCurrentItemActive(isActive: boolean) {
    const currentItem = this.filesAndFoldersView.selectedItem;
    console.log(currentItem)
    if (currentItem) {
      const databasePath = this.filesAndFoldersView.selectedDatabasePath;
      if (currentItem.type === 'Image') {
        this.filesAndFoldersCollection.doc(`${ databasePath }${ currentItem.id }`).update({
          isActive: isActive
        })
      }
      else {
        const pathSplit = databasePath.split('/')
        const databaseFolderPath = pathSplit.slice(0, -2).join('/')
        this.filesAndFoldersCollection.doc(`${ databaseFolderPath }`).update({
          isActive: isActive
        })
      }
    }
  }

  deleteCurrentItem() {
    const currentItem = this.filesAndFoldersView.selectedItem;
    if (!currentItem) {
      return;
    }
    this.deleteItem(currentItem);
  }

  private deleteItem(item: Image | Folder) {
    if (item.type === 'Image') {
      const documentPath: string = item.databasePath;
      const storagePath: string = item.storagePath;
      console.log("Deleting image", item)
      this.databaseService.doc(documentPath).delete().then(() => {
        this.storageService.ref(storagePath).delete();
        this.filesAndFoldersView.clearSelection();
      })
    }
    else {
      console.log("Deleting folder", item)
      let subscription = item.items.subscribe(items => items.forEach(child => this.deleteItem(child)))

      const databaseRef = this.databaseService.doc(item.databasePath);
      console.log(item.databasePath);
      databaseRef.delete().then(() => {
        subscription.unsubscribe();
        this.filesAndFoldersView.clearSelection();
      });
    }
  }
}
