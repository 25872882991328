import { Directive, PLATFORM_ID, Inject, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDisplayMath]'
})
export class DisplayMathDirective {
  constructor( @Inject(PLATFORM_ID) private platformId: Object, element: ElementRef) 
  {
    if (window.MathJax !== undefined)
    {
      console.log("Trying to typeset math")
      setTimeout( ()=> window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub]), 3000);
    }
    
  }
}
