<ng-template #viewTemplate>
    <ul class="note-box-images-list" [ngClass]="{ 'root-list': isRootLevel, 'leaf-list': !hasOneFolder || !currentItem || currentItem.type === 'Image', 'hidden': !isVisible, 'visible': isVisible }" [hidden]="!isVisible">
        <li class="icon-thumbnail" [ngClass]="{ 'folder-item': item.type === 'Folder', 'selected': currentItem === item }" *ngFor="let item of filesAndFolders | async" (click)="onItemClicked(item)">       
            <img *ngIf="item.type === 'Image'" src="{{ item.downloadUrl | async}}" />
            <fa-icon *ngIf="item.type === 'Folder'" class="folder-icon" [icon]="faFolder" size="2x"></fa-icon>
            <div>{{ item.name }}</div>
            <fa-icon *ngIf="item.type === 'Folder'" class="chevron-icon" [icon]="faChevronRight" size="1x"></fa-icon>
        </li>
        <li class="icon-thumbnail" *ngIf="filesAndFolders === null || (filesAndFolders | async)?.length === 0">
            <div>No images or folders uploaded yet!</div>
        </li>
        <li class="spacer" (click)="clearSelection()"></li>
    </ul>

    <ng-container *ngIf="hasOneFolder">
        <app-folder-view 
            [isRootLevel]="false" 
            [isVisible]="currentItem !== null && currentItem.type === 'Folder'"
            [filesAndFolders]="currentItem && currentItem.type === 'Folder' ? currentItem.items : null"
            #subdirectoryView>
        </app-folder-view>
        <ng-container *ngTemplateOutlet="subdirectoryView.componentTemplate"></ng-container>
    </ng-container>

</ng-template>

