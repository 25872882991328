import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ArticleModel, ArticleNode, ArticleNodeType } from '../models/ArticleModel';
import { ArticleBuilder } from '../models/ArticleBuilder';

import { ActivatedRoute } from '@angular/router';
import { ArticlesTableOfContentsService } from '../articles-table-of-contents.service';
import { Title } from '@angular/platform-browser'

import { PrismHighlightDirective } from '../prism-highlight.directive'
import { prototype } from 'events';
import { TypeofExpr } from '@angular/compiler';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss'],
  inputs: ['articleModel']
})

export class ArticleComponent implements OnInit {
  public articleModel: ArticleModel;
  public title: string;
  public date: string;
  public ArticleNodeType: typeof ArticleNodeType = ArticleNodeType;

  constructor(activatedRoute: ActivatedRoute, articleTableOfContents: ArticlesTableOfContentsService, titleService: Title) 
  { 
    this.articleModel = new ArticleModel();
    let articleName = activatedRoute.snapshot.params["articleName"];
    articleTableOfContents.getArticle(articleName, data => {
      this.articleModel = data as ArticleModel;
      titleService.setTitle(`Austin Simpson - ${this.articleModel.title}`)
    });
    this.date = this.articleModel.date;
  }

  ngOnChanges(changes: SimpleChanges){
    if (changes.articleModel)
    {
      console.log(changes)
      this.title = changes.articleModel.currentValue.title;
      this.date = changes.articleModel.currentValue.date;
      this.articleModel = changes.articleModel.currentValue as ArticleModel;
    }
  }

  ngOnInit() {
  }

}
