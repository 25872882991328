import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../authorization.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public email: string;
  public password: string

  constructor(public authService: AuthorizationService) { }

  ngOnInit(): void {
  }

  login() {
    this.authService.loginWithEmailAndPassword(this.email, this.password)
    this.email = ''
    this.password = ''
  }
  logout() {
    this.authService.logout();
  }
}
