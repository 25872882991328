import { Component, OnInit } from '@angular/core';
import { ArticleModel } from '../models/ArticleModel';
import { ActivatedRoute } from '@angular/router';
import { ArticlesTableOfContentsService } from '../articles-table-of-contents.service';
import { Title } from '@angular/platform-browser'

@Component({
  selector: 'app-articles',
  templateUrl: './articles-page.component.html',
  styleUrls: ['./articles-page.component.scss']
})
export class ArticlesPageComponent implements OnInit {
  articles: ArticleModel[];

  constructor(activatedRoute: ActivatedRoute, articleTableOfContents: ArticlesTableOfContentsService, titleService: Title ) { 
    titleService.setTitle("Austin Simpson - Articles")
    this.articles = articleTableOfContents.getAll()
  }

  ngOnInit() {
  }

}
